import { Component, useRef, useEffect, createRef } from 'react'
import {
  Button,
  Input,
  Container,
  Box,
  Flex,
  Spacer,
  Textarea
} from '@chakra-ui/react'
import Alert from './components/Alert'
import './App.css';

const LOCAL_STORAGE_KEY = 'todos'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      desc: '',
      todos: [],
      edit: '',
      visibleAlert: false,
      toBeDelete: null,
      currentRef: null
    }
    this.input = createRef()
    this.textarea = createRef()
  }

  componentDidMount = () => {
    const getData = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    this.setState({
      todos: getData ? JSON.parse(getData) : []
    })
    this.input.current.focus()
  }

  handleChange = (e) => {
    // console.log(this.textarea.current.value)
    this.setState({
      name: this.input.current.value,
      desc: this.textarea.current.value
    })
  }

  handleSubmit = async (e) => {
    const submitted = this.input.current.value
    if (submitted) {
      if (this.state.edit) {
        const todos = await [...this.state.todos].map((todo) => {
          if (todo.name === this.state.edit) {
            todo = {
              name: this.state.name,
              desc: this.state.desc
            }
          }
          return todo
        })
        await this.setState({
          todos
        })
        if (this.state.currentRef) {
          this.state.currentRef.style.background = 'blue'
          setTimeout(() => {
            this.state.currentRef.style.background = 'green'
          }, 1000);
          this.state.currentRef.scrollIntoView()
        }
      } else {
        await this.setState({
          todos: [...this.state.todos, { name: this.state.name, desc: this.state.desc }]
        })
        console.log(this.state.todos)
      }
      this.setLocalStorage()
      this.setState({
        name: '',
        desc: '',
        edit: ''
      })
    }
    e.preventDefault()
  }

  handleEdit = (e, ref) => {
    this.setState({
      edit: e.name,
      name: e.name,
      desc: e.desc,
      currentRef: ref
    })
    this.input.current.scrollIntoView()
    this.input.current.focus()
  }

  beforeDelete = (todo) => {
    this.setState({
      toBeDelete: todo.name,
      visibleAlert: true
    })
  }

  handleDelete = async (e) => {
    // console.log(this.state, e)
    const todos = await [...this.state.todos].filter((item) => {
      return item.name !== this.state.toBeDelete
    })
    await this.setState({
      todos
    })
    this.setState({ visibleAlert: false })
    this.setLocalStorage()
  }

  setLocalStorage = () => {
    if (this.state.todos.length) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.state.todos))
    } else {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY)
    }
  }

  render() {
    return (
      <div className="App">
        <Container>
          <h1 className="h1 text-center">Todo LIST</h1>
          <form
            onSubmit={this.handleSubmit}>
            <Input
              my={2}
              ref={this.input}
              value={this.state.name}
              placeholder="> What do you wanna do?"
              onChange={this.handleChange}
            />
            <Textarea
              my={2}
              ref={this.textarea}
              value={this.state.desc}
              placeholder="> Description...."
              onChange={this.handleChange}
            />
            <Flex>
              <Spacer />
              <Button onClick={this.handleSubmit}>
                Save
              </Button>
            </Flex>
          </form>
          <TodoList
            todos={this.state.todos}
            onListClick={this.handleEdit}
            onListDelete={this.beforeDelete}
          />
        </Container>
        <Alert
          title="Apakah yakin?"
          desc="Tidak bisa di UNDO yah!"
          isOpen={this.state.visibleAlert}
          onCancel={(e) => this.setState({ visibleAlert: false })}
          onConfirm={(e) => this.handleDelete()}
        />
      </div>
    );
  }
}

function TodoList({ todos, onListClick, onListDelete }) {
  const elementRefs = useRef([])

  useEffect(() => {
    elementRefs.current = elementRefs.current.slice(0, todos.length)
  }, [todos])

  const handleClick = (e, key) => {
    const ref = elementRefs.current[key]
    onListClick(e, ref)
  }

  const buttons = [
    {
      color: 'teal',
      onClick: handleClick,
      label: 'Edit',
    },
    {
      color: 'red',
      onClick: onListDelete,
      label: 'Delete',
    }
  ]

  const listItems = todos.map((todo, todoKey) => {
    return (
       <Box
        ref={(el) => elementRefs.current[todoKey] = el}
        key={todoKey}
        bg="green"
        w="100%"
        p={4}
        my={4}
        color="white">
        <Flex align="center">
          {todo.name}
          <Spacer/>
          {
            buttons.map(button => {
              return (
                <Button
                  key={button.color}
                  colorScheme={button.color}
                  ml={2}
                  onClick={() => button.onClick(todo, todoKey)}>
                  {button.label}
                </Button>
              )
            })
          }
        </Flex>
      </Box>
    )
  })
  return (
    <div className="TodoList">
      { listItems }
    </div>
  )
}

export default App;
